.menu {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: rgba(0, 0, 0, 0);
    background-image: url("../ressources/images/logo.svg");
    background-repeat: no-repeat;
    background-size: 64px;
    background-position-x: right;
    background-position-y: 8px;
    opacity: 0.8;

    height: 100%;
    width: 50%;
    min-width: 360px;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 16px;

    position: fixed;
    top: 0;
    left: calc(64px - 24px - 50%);
    z-index: 1;

    transition: all 500ms ease-in-out;
}

.menu:hover {
    background-color: rgba(0, 0, 0, 1);
    background-position-x: 8px;
    background-position-y: -64px;
    opacity: 1;

    overflow-y: auto;

    left: 0;

    animation: show-menu 500ms ease-in-out;
}

@keyframes show-menu {
    from {overflow: hidden}
}

.menu-logo {
    width: 100px;
    height: 100px;
}

.menu-layout {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 1rem;

    width: 100%;
}

.submenu {
    flex: 1 1 40%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu hr {
    align-self: stretch;

    border: 1px solid rgba(255, 255, 255, 0);

    margin: 15px 0;
    width: 100%;

    transition: all 500ms ease-in-out;
}

.menu:hover hr {
    border-color: rgba(255, 255, 255, 0.25);
}

.menu .subtitle {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.8em;
    font-style: italic;
    margin: -15px 0 10px 0;
    padding-top: 8px;
}

.menu a {
    color: white;
    text-decoration: none;
    margin: 5px 0;
    white-space: nowrap;
    opacity: 0;

    transition: opacity 500ms ease-in-out;
}

.menu:hover a {
    opacity: 1;
}

.menu a:last-of-type {
    margin-bottom: 32px;
}

.menu small {
    color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 720px) {
    .menu {
        background: rgba(0, 0, 0, 1);
        opacity: 1;

        position: relative;
        left: auto;
        height: auto;
        width: auto;
        min-width: auto;
    }

    .menu-layout {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: stretch;
    }

    .menu hr {
        border-color: rgba(255, 255, 255, 0.25);
    }

    .menu a {
        white-space: initial;
        text-align: center;
        opacity: 1;
    }
}
