.alert {
    display: grid;
    grid-template-areas: "icon title" "icon body" "icon footer";
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr auto;
    gap: 0.5rem;

    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 10px;
    color: white;

    margin: 1rem;
    padding: 0.75rem;
}

.alert .icon {
    grid-area: icon;
    place-self: center;

    object-fit: cover;
    min-width: 4rem;

    animation: alert-icon 1.5s alternate infinite ease-in-out;
}

.alert .title {
    grid-area: title;
    place-self: end start;

    font-size: 1.2rem;
    font-weight: bold;

    margin: 0;
}

.alert .body {
    grid-area: body;
    align-self: center;

    font-size: 0.8rem;
    font-style: italic;

    margin: 0;
}

.alert .footer {
    grid-area: footer;
    place-self: center  ;

    font-size: 0.8rem;
}

.alert a {
    color: white;
}

@keyframes alert-icon {
    from {
        transform: rotate(-6deg);
    }
    to {
        transform: rotate(6deg);
    }
}
