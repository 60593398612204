.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #000;
    color: #fff;
    font-size: 2em;
    font-weight: bold;

    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2;

    transition: all 1s ease-out;
}

.loading[hidden] {
    opacity: 0;
}

.loading .animation {
    flex: 1;

    background: url("../ressources/images/loading.svg") no-repeat center center;

    aspect-ratio: 1/1;
    max-width: 200px;
    max-height: 200px;
}
