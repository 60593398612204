html {
  height: 100%;
}

html, body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

body > * {
  height: 100%;
}

/* == MAIN == */

.background-attachment {
  background-size: cover;
  background-position: center center;
  filter: blur(20px);
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  top: 0;
  transform: scale(1.2);
  z-index: -1;
}

main {
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  min-height: 100%;
  padding: 1% 10%;

  display: flex;
  align-items: center;
  flex-direction: column;
}

img.cover {
  box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 10px;
  max-width: 400px;
  width: 50%;
}

img.screenshot {
  box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 10px;
  max-width: 90%;
  max-height: 60vh;
}

h1 {
  font-size: 3em;
  text-align: center;
}

h2 {
  margin-top: 30px;
}

.services {
  align-content: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
}

.service-item {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 10px inset;
  display: flex;
  flex-direction: column;
  color: #fff;
  margin: 10px;
  padding: 10px 20px;
  text-decoration: none;
  width: 200px;
}

.service-item .img-box {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0;
}

.default-background {
  background-image: url("./ressources/images/default-background.png");
}

.pills {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  margin: 24px 0 8px 0;
}

.pills > * {
  background-color: #868686;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 10px;
  padding: 4px 8px;
}
